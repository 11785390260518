{
  "name": "rlm-fe-customer",
  "version": "1.4.5",
  "license": "PrimeNG Commercial",
  "scripts": {
    "ng": "ng",
    "config": "ts-node ./scripts/setenv.ts",
    "start": "npm run config -- --environment=local && ng serve --configuration local",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "refreshVSToken": "vsts-npm-auth -config .npmrc -force",
    "prepare": "husky"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.1.1",
    "@angular/cdk": "^18.1.1",
    "@angular/common": "^18.1.1",
    "@angular/compiler": "^18.1.1",
    "@angular/core": "^18.1.1",
    "@angular/forms": "^18.1.1",
    "@angular/platform-browser": "^18.1.1",
    "@angular/platform-browser-dynamic": "^18.1.1",
    "@angular/router": "^18.1.1",
    "@azure/app-configuration": "^1.6.1",
    "@azure/msal-angular": "^3.0.22",
    "@azure/msal-browser": "^3.19.1",
    "@azure/storage-blob": "^12.24.0",
    "@azure/web-pubsub-client": "^1.0.1",
    "@fullcalendar/angular": "^6.1.15",
    "@fullcalendar/core": "^6.1.15",
    "@fullcalendar/daygrid": "^6.1.15",
    "@fullcalendar/interaction": "^6.1.15",
    "@fullcalendar/timegrid": "^6.1.15",
    "@microsoft/applicationinsights-web": "^3.3.0",
    "@wgi/rlm": "^18.0.2",
    "awesome-phonenumber": "^6.10.0",
    "chart.js": "^4.4.3",
    "chartjs-adapter-moment": "^1.0.1",
    "moment": "^2.30.1",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "17.18.5",
    "prismjs": "^1.29.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.3.0",
    "web-animations-js": "^2.3.2",
    "zone.js": "~0.14.5"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.1",
    "@angular-eslint/builder": "^18.1.0",
    "@angular-eslint/eslint-plugin": "^18.1.0",
    "@angular-eslint/eslint-plugin-template": "^18.1.0",
    "@angular-eslint/schematics": "^18.1.0",
    "@angular-eslint/template-parser": "^18.1.0",
    "@angular/cli": "^18.1.1",
    "@angular/compiler-cli": "^18.1.1",
    "@types/jasmine": "^5.1.4",
    "@types/jasminewd2": "^2.0.13",
    "@types/node": "^20.14.11",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "dotenv": "^16.4.5",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-no-loops": "^0.3.0",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-rxjs": "^5.0.3",
    "eslint-plugin-rxjs-angular": "^2.0.1",
    "husky": "^9.1.1",
    "jasmine-core": "^5.2.0",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.4.3",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-junit-reporter": "^2.0.1",
    "karma-spec-reporter": "^0.0.36",
    "ng-mocks": "^14.13.0",
    "prettier": "^3.3.3",
    "prettier-eslint": "^16.3.0",
    "puppeteer": "^22.13.1",
    "ts-node": "~8.3.0",
    "typescript": "~5.4.5",
    "yargs": "^17.7.2"
  }
}
