import { Environment } from '../app/core/models/environment/environment.model';

export const environment: Environment = {
  production: '#{fe-environment-production}#',
  // eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,@typescript-eslint/restrict-plus-operands,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/restrict-template-expressions,@typescript-eslint/no-unsafe-call
  appVersion: `${require('../../package.json').version}`,
  feEnvironmentCustomerAppUrl: '#{fe-environment-customer-app-url}#',
  feEnvironmentBackendUrl: '#{fe-environment-backend-url}#',
  feEnvironmentBackendScope: '#{fe-environment-customer-backend-scope}#',
  feEnvironmentB2cClientId: '#{fe-environment-customer-b2c-client-id}#',
  feEnvironmentB2cTenant: '#{fe-environment-customer-b2c-tenant}#',
  feEnvironmentB2cTenantName: '#{fe-environment-customer-b2c-tenant-name}#',
  feEnvironmentB2cPolicySignUpSignIn:
    '#{fe-environment-customer-b2c-policy-sign-up-sign-in}#',
  feEnvironmentLoggingApplicationInsightsKey:
    '#{fe-environment-logging-application-insights-key}#',
  feEnvironmentIdleTimeoutMinutes: '#{fe-environment-idle-timeout-minutes}#',
  feEnvironmentLoggingConsole: '#{fe-environment-logging-console}#',
  feEnvironmentFeatureConsumption: '#{fe-environment-feature-consumption}#',
  b2cEnvironmentCustomerDataProtectionAgreementUrl:
    '#{b2c-environment-customer-data-protection-agreement-url}#',
  feBrandingContentCustomerDataPrivacyAgreementUrl:
    '#{fe-branding-content-customer-data-privacy-agreement-url}#',
  feBrandingContentCustomerImpressumUrl:
    '#{fe-branding-content-customer-impressum-url}#',
  feBrandingContentCustomerPageTitle:
    '#{fe-branding-content-customer-page-title}#',
  feEnvironmentCustomerTableShortPageReport:
    '#{fe-environment-customer-table-short-page-report}#',
  feEnvironmentCustomerTableShortEntityCount:
    '#{fe-environment-customer-table-short-entity-count}#',
  feEnvironmentIsNbb: '#{fe-environment-is-nbb}#',
  feEnvironmentStage: '#{wgi-feature-flags-stage}#',
  feEnvironmentAppConfigurationReadonlyConnectionString:
    '#{wgi-feature-flags-readonly-connection-string}#',
  feEnvironmentKeyPrefix: '#{wgi-feature-flags-key-prefix}#',
  feEnvironmentPubSubHub: '#{wgi-pubsub-hub}#',
};
